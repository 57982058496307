import default_common from './common'
import default_dashboard from './dashboard'
import default_services from './services'
import default_management from './management'

import common from './common'
import dashboard from './dashboard'
import management from './management'
import * as services from './services'

export {
    common,
    dashboard,
    services,
    management
}

const ROUTES = [
    ...default_common,
    ...default_dashboard,
    ...default_services,
    ...default_management
]

export default ROUTES