import * as React from 'react'

import * as Services from './services'
import * as Dashboard from './dashboard'
import * as Management from './management' 
import * as Login from './login'

const HomePageContainer = React.lazy(() => import('./home/PageContainer'))
const AdminPageContainer = React.lazy(() => import('./admin/PageContainer'))
const NotFoundPageContainer = React.lazy(() => import('./not-found/PageContainer'))

export {
    HomePageContainer,
    AdminPageContainer,
    NotFoundPageContainer,
    Services,
    Dashboard,
    Management,
    Login
}