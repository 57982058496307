import * as React from 'react'
import useTheme from '@mui/material/styles/useTheme'
import { gql, useLazyQuery } from '@apollo/client'
import Page from './Page'
import PageLoading from './PageLoading'
import { Navigate, useNavigate } from 'react-router-dom'
import { useRecoilValue } from 'recoil'
import { ApplicationUserState } from '../../recoil/user/Atom'
import UnauthorizedPage from './UnathorizedPage'
import useLogin from '../../hooks/common/user/useLogin'
import useLogout from '../../hooks/common/user/useLogout'

const UPDATE_INTERVAL = process.env.NODE_ENV === 'production' ? 30000 : 86400000

const VERIFY_TOKEN = gql`

query VerifyAppToken($input: VerifyTokenInput) {
    VerifyAppJWTToken(input: $input){
        appToken
        accessToken
        refreshToken
    }
}

`

const PrivateRoute = (props) => {

    const theme = useTheme()
    const [verifyTokenAPI, { loading, data, error }] = useLazyQuery(VERIFY_TOKEN, {
        fetchPolicy: 'no-cache',
        nextFetchPolicy: 'network-only'
    })
    const navigate = useNavigate()
    const userState = useRecoilValue(ApplicationUserState)
    const { StoreTokenInLocalStorage } = useLogin()
    const { cleanUpLocalStorage } = useLogout()
    const { component: Component, roles, ...rest } = props

    const verifyToken = async () => {

        try {
        
            /**
             * Added a dummy input so that the query 
             * will be executed everytime instead of fetching from Cache
             */
            const result = await verifyTokenAPI({
                variables: {
                    input: {
                        timestamp: Date.now().toString()
                    }
                }
            })

            StoreTokenInLocalStorage(result.data.VerifyAppJWTToken)

        } catch (error) {

            cleanUpLocalStorage()
            navigate('/', { replace: true })

        }

    }

    React.useEffect(() => {

        verifyToken()

    }, [Component])

    if(loading){
        
        return (
            <Page component={PageLoading} />
        )

    }

    if(error){

        cleanUpLocalStorage()
        return (
            <Navigate to="/" replace={false} />
        )

    }

    if(data && data?.VerifyAppJWTToken) {

        if(!roles.some(e => userState.role.includes(e))){

            return (
                <React.Fragment>
                    <Page component={UnauthorizedPage} fullPageWidth={false} />
                </React.Fragment>
            )
    
        }
    
        return (
            <React.Fragment>
                <Page component={Component} {...rest} />
            </React.Fragment>
        )

    } else {

        return (
            <React.Fragment>
                <Page component={PageLoading} />
            </React.Fragment>
        )

    }

}

export default PrivateRoute