import * as Pages from '../../../../pages'

const ROUTES = [
    {
        link: '/',
        title: 'CloudInvent Login',
        routeId: 1000,
        roles: ['viewer'],
        nested: false,
        component: Pages.Login.SignInPageContainer,
        fullPageWidth: true,
        header: false,
        authenticated: false,
        sideNav: false,
        independent: true,
        external: false,
        routing: true
    },
    {
        link: '/home',
        title: 'Home',
        routeId: 2000,
        roles: ['viewer'],
        nested: false,
        component: Pages.HomePageContainer,
        fullPageWidth: true,
        header: false,
        authenticated: false,
        sideNav: false,
        independent: false,
        external: false,
        routing: true
    },
    {
        link: '/cloudinvent-admin',
        title: 'CloudInvent Admin',
        routeId: 2001,
        roles: ['cloudinvent-admin'],
        nested: false,
        component: Pages.AdminPageContainer,
        fullPageWidth: true,
        header: false,
        authenticated: true,
        sideNav: false,
        independent: false,
        external: false,
        routing: true,
    },
]

export default ROUTES