import * as React from 'react'
import { useResetRecoilState } from 'recoil'
import { ApplicationUserState } from '../../../recoil/user/Atom'
import { useNavigate } from 'react-router-dom'
import { gql, useApolloClient, useMutation } from '@apollo/client'

const LOGOUT_USER = gql`
mutation logoutExternalUser{
    logoutExternalUser
}
`

const useLogout = () => {

    /** Clean up the user information on logout */
    const resetUserState = useResetRecoilState(ApplicationUserState)
    const client = useApolloClient()

    const [cognitoLogout, { loading: logoutLoading, error: logoutError, data: logoutData, called: logoutCalled}] = useMutation(LOGOUT_USER)

    let navigate = useNavigate()

    const serverSideLogout = async () => {
        try {
            await cognitoLogout()
        } catch (error) {
            
        }
    }

    const cleanUpLocalStorage = () => {

        window.localStorage.removeItem('cloudinvent-app-token')
        window.localStorage.removeItem('cloudinvent-access-token')
        window.localStorage.removeItem('cloudinvent-refresh-token')

    }

    const cleanUpState = async () => {

        await serverSideLogout()
        resetUserState()
        client.clearStore()
        cleanUpLocalStorage()

    }

    const logoutUser = () => {

        cleanUpState()
        navigate('/', { replace: true})

    }

    return {
        logoutUser,
        cleanUpState,
        cleanUpLocalStorage
    }


}

export default useLogout