import * as React from 'react'
import { useSetRecoilState } from 'recoil'
import { ApplicationUserState } from '../../../recoil/user/Atom'
import jwtDecode from 'jwt-decode'
import { useLocation, useNavigate } from 'react-router-dom'

const useLogin = () => {

    const setApplicationUserState = useSetRecoilState(ApplicationUserState)
    const navigate = useNavigate()
    const currentLocation = useLocation()

    const postLoginUserInitialization = () => {

        const token = window.localStorage.getItem('cloudinvent-app-token')

        if(token) {

            const decoded = jwtDecode(token)
            const isInternalUser = decoded.role.includes('cloudinvent-admin')
            setApplicationUserState({
                ...decoded,
                ...(isInternalUser ? { internalUser: true } : {})
            })

        }

    }

    const StoreTokenInLocalStorage = (args) => {

        /** Store the new JWT token in the localstorage */
        window.localStorage.setItem('cloudinvent-app-token', args.appToken)
        window.localStorage.setItem('cloudinvent-access-token', args.accessToken)
        window.localStorage.setItem('cloudinvent-refresh-token', args.refreshToken)

    }

    const postAuthentication = (args) => {

        StoreTokenInLocalStorage(args)

        /** Initialize the user state post login */
        postLoginUserInitialization()

        /**
         * Check if the user is an internal user or external user
         * Internal Users are cloudinvent admin users
         */
        if(Object.hasOwn(args, 'internalUser') && args.internalUser) {

            navigate('/cloudinvent-admin', { replace: true})

        } else {

            /** If the user is at the login page, re-direct them to the dashboard page */
            if(currentLocation.pathname === '/'){

                navigate('/dashboard/executive-summary/aws', { replace: false })

            }

        }

    }

    return {
        postLoginUserInitialization,
        postAuthentication,
        StoreTokenInLocalStorage
    }


}

export default useLogin