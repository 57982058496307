import * as React from 'react'
import { Box, IconButton, Typography, useTheme } from '@mui/material'
import { useRecoilValue } from 'recoil'
import { ApplicationUserState } from '../../../recoil/user/Atom'
import { useNavigate } from 'react-router-dom'
import { EditRounded } from '@mui/icons-material'

const AdminSelectedCustomerContainer = (props) => {

    const theme = useTheme()
    const userState = useRecoilValue(ApplicationUserState)
    let navigate = useNavigate()

    return (
        <React.Fragment>
            <Box sx={{display: 'flex', justifyContent:'flex-end', alignItems:'center', gap: theme.spacing(2), px: theme.spacing(2)}}>
                <Typography variant="subtitle1" sx={{fontWeight: '700', color: theme.palette.mode === 'light' ? theme.palette.text.primary : '#ffffff'}}>Customer View: {userState.customer}</Typography>
                <IconButton onClick={() => navigate('/cloudinvent-admin')}>
                    <EditRounded sx={{color: theme.palette.mode === 'light' ? theme.palette.primary.main : '#ffffff'}} />
                </IconButton>
            </Box>
        </React.Fragment>
    )

}

export default AdminSelectedCustomerContainer