import * as Pages from '../../../../pages'

const ROUTES = [
    {
        link: '/management',
        title: 'Management Console',
        routeId: 6000,
        roles: ['management-viewer'],
        nested: false,
        component: null,
        fullPageWidth: true,
        header: true,
        authenticated: true,
        sideNav: true,
        independent: false,
        external: false,
        routing: false,
    },
    {
        link: '/management/budget',
        title: 'Budget',
        routeId: 6001,
        roles: ['management-budget-viewer'],
        nested: false,
        component: Pages.Management.BudgetPageContainer,
        fullPageWidth: false,
        header: false,
        authenticated: true,
        sideNav: true,
        independent: false,
        external: false,
        routing: true,
    },
]

export default ROUTES