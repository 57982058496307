import { Box, CircularProgress, Grid, useTheme } from '@mui/material'
import * as React from 'react'

const PageLoading = (props) => {

    const theme = useTheme()

    return(
        <React.Fragment>
            <Box sx={{display: 'flex', minHeight:'100%', minWidth: '100%', overflow: 'hidden'}}>
                <Grid container alignItems="center" justifyContent="center" spacing={1} sx={{display: 'flex'}}>
                    <Grid item xs={1}>
                        <CircularProgress />
                    </Grid>
                </Grid>
            </Box>
        </React.Fragment>
    )

}

export default PageLoading